<template lang="pug">
Auth(@loginFailed="onFailedAuthentication")
  Header(:content="headerContent")
  DrawerMenu(v-if="uid"
    :uid="uid" :show="showMenu" @close="toggleMenu")
  div.wrap-account
    ModuleAccount(v-if="uid")
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-account {
  margin-top: $header_height;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/shared/Auth'
const {
  mapState: mapStateAuth,
  mapActions: mapActionsAuth
} = createNamespacedHelpers('auth')
import Header from '@/components/shared/Header'
import ModalPage from '@/components/shared/ModalPage.vue'
import ModuleAccount from '@/components/module/ModuleAccount'
import DrawerMenu from '@/components/shared/DrawerMenu'

export default {
  components: {
    Auth,
    Header,
    DrawerMenu,
    ModalPage,
    ModuleAccount
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  data() {
    return {
      headerContent: {
        title: 'アカウント',
        left: {
          icon: 'mdi-menu',
          action: this.toggleMenu
        },
      },
      showMenu: false,
    }
  },
  created() {
    //
  },
  methods: {
    onFailedAuthentication() {
      this.$router.push('/sign-in')
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
    }
  }
}
</script>
