<template lang="pug">
  div.d-flex.flex-column.wrap-module-account
    div.list-label
      div.list-content.py2
        span.label.fz12 プラン設定
    div.list-item
      div.list-content.f.fm.flex-between.py12
        span.fz16 {{ team ? 'Teamプラン' : 'Basicプラン' }}
          span(v-if="team && !!team.downgradedAt")
            br
            small {{ getDowngradedAt() }}にダウングレードする
        span(@click="$router.push('/select-plan')").btn-active.fz14 プランを更新

    div(v-if="team")
      div.list-label
        div.list-content.py2
          span.label.fz12 アカウント連携
      //- hide zoom login by finishing review
      div.list-item
        div.list-content.py12
          ZoomSignIn
      div.list-item
        div.list-content.py12
          MicrosoftSignIn
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-account {
  .list-label,
  .list-item {
    border-bottom: $basic_border;
    .list-content {
      width: $base_width_percent;
      max-width: $base_max_width_px;
      margin: 0 auto;
      .label {
        color: $secondary_text_color;
      }
      .btn-active {
        color: $active_color;
      }
      .btn-connect {
        color: $active_color;
        &.connected {
          color: $secondary_text_color;
        }
      }
    }
  }
}
</style>

<script>
import format from 'date-fns/format'
import database from '@/database'
import MicrosoftSignIn from '@/components/sign-in/microsoft-sign-in'
import ZoomSignIn from '@/components/sign-in/zoom-sign-in'

import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
const teamStore = createNamespacedHelpers('team')
const planSettingsStore = createNamespacedHelpers('planSettings')

export default {
  components: {
    MicrosoftSignIn,
    ZoomSignIn
  },

  computed: {
    ...mapStateAuth(['uid', 'userAuthEmail']),
    ...teamStore.mapState(['team']),
    zoomAccountIsConnected() {
      // please return status whther this account has been connected or not
      return true
    },
    microsoftAccountIsConnected() {
      // please return status whther this account has been connected or not
      return false
    }
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    ...planSettingsStore.mapMutations(['clearPlanSettings']),
    getDowngradedAt() {
      if (this.team && this.team.downgradedAt) {
        return format(this.team.downgradedAt.toDate(), 'yyyy-MM-dd HH:mm')
      } else {
        return ''
      }
    },
    async onZoomConnection() {
      if (this.zoomAccountIsConnected) {
        // disconnect
      } else {
        // connect
      }
    },
    async onMicrosoftConnection() {
      if (this.microsoftAccountIsConnected) {
        // disconnect
      } else {
        // connect
      }
    }
  }
}
</script>
